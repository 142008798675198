<template>
    <el-dialog title="Google Authenticator 2FA金鑰"
               v-model="show"
               :show-close="false"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="480px"
               center>
        <div style="text-align: center;">

            <div style="height:50px">
                <div v-if="loading"
                     class="loading-btn">
                    <i class="el-icon-loading"></i>
                </div>
                <el-button v-else
                           type="text"
                           class="google2fa-text"
                           size="large"
                           v-clipboard:copy="validateCode"
                           v-clipboard:success="clipboardSuccess"> {{validateCode}}
                    <i class="el-icon-document-copy"></i>
                </el-button>
            </div>
            <div class="timer-text">{{time}}s後更新</div>
            <el-button @click="closeDialog">返回</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { validateCode } from '@/api/index'
export default {
    props: {
        google2faDialogVisible: {
            type: Boolean,
            default: false,
        },
        google2fa: {}, //可配對資源總數
    },
    emits: ['close-dialog'],
    watch: {
        google2faDialogVisible() {
            this.show = this.google2faDialogVisible
            if (this.show) {
                this.getValidateCode()
                this.time = 30
            }
        },
    },
    data() {
        return {
            show: this.google2faDialogVisible,
            validateCode: '',
            timer: null,
            time: 30,
            loading: false,
        }
    },
    methods: {
        getValidateCode() {
            this.loading = true
            validateCode({ key: this.google2fa })
                .then((res) => {
                    if (res.code == 300) {
                        this.validateCode = res.data.validateCode
                        this.loading = false
                        this.time = 30
                        this.setCountfown()
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        setCountfown() {
            this.timer = setInterval(() => {
                this.time--
                if (this.time == 0) {
                    clearInterval(this.timer)
                    this.getValidateCode()
                }
            }, 1000)
        },
        closeDialog() {
            clearInterval(this.timer)
            this.$emit('close-dialog')
        },
        clipboardSuccess() {
            this.$message.success('複製成功')
        },
    },
    beforeUnmount() {
        clearInterval(this.timer)
    },
}
</script>


<style lang="scss" scoped>
.loading-btn {
    font-size: 24px;
}
.google2fa-text {
    font-size: 24px;
}
.timer-text {
    padding-bottom: 10px;
}
</style>