<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/userInformation' }">會員資料</el-breadcrumb-item>
                <el-breadcrumb-item>域名來源編輯</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <h3 class="title">域名來源設定</h3>
            </div>
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="來源註記">
                    <el-input v-model="formInline.remark"
                              placeholder="請輸入來源註記關鍵字"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查詢</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clearSearch">清除</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      border
                      class="table">
                <el-table-column prop="name"
                                 label="網域來源"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="key1"
                                 label="API KEY"
                                 align="center">
                </el-table-column>
                <el-table-column prop="remark"
                                 label="來源註記"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="status"
                                 label="來源狀態"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="google_2fa"
                                 label="Google金鑰"
                                 width="180"
                                 align="center">
                    <template #default="scope">
                        <el-button v-if="scope.row.google_2fa != null"
                                   type="text"
                                   @click="handleShowGoogle2FA(scope.row.google_2fa)">查看金鑰</el-button>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
        <!--Google 2FA -->
        <Google2FA :google2faDialogVisible="google2faDialogVisible"
                   :google2fa="google2fa"
                   @close-dialog="google2faDialogVisible = false">
        </Google2FA>
    </div>
</template>

<script>
import { memberAccount } from '../../api/index'
import Google2FA from '../../components/Google2FA.vue'

export default {
    components: { Google2FA },
    data() {
        return {
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 1,
            google2faDialogVisible: false, //google2fa彈窗
            google2fa: {},
            formInline: {
                remark: '',
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                page: this.pageIndex,
                remark: this.formInline.remark,
            }
            memberAccount(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                    }
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        handleShowGoogle2FA(googlr_2fa) {
            this.google2fa = googlr_2fa
            this.google2faDialogVisible = true
        },
        // 搜尋
        handleSearch() {
            window.history.pushState({}, 0, 'https://' + window.location.host + '/domainSourceList?remark=' + this.formInline.remark + '&page=' + this.pageIndex)
            this.getData()
        },
        // 清除搜尋
        clearSearch() {
            this.formInline = { remark: '' }
            window.history.pushState({}, 0, 'https://' + window.location.host + '/domainSourceList?remark=' + this.formInline.remark + '&page=' + this.pageIndex)
            this.getData()
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    padding-right: 20px;
}
.handle-box {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.red {
    color: #ff0000;
}
.selectWidth {
    width: 100%;
}
.excel-upload-input {
    display: none;
}
</style>
<style lang="scss">
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}
</style>